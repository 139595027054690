<template>
  <el-dialog
    append-to-body
    width="800px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
    >
      <el-form-item prop="create_time" label="反馈时间">
        <el-input :readonly="true" v-model="form.data.create_time"></el-input>
      </el-form-item>
      <el-form-item prop="type" label="用户来源">
        <el-input :readonly="true" v-model="form.data.type"></el-input>
      </el-form-item>
      <el-form-item prop="account" label="注册号码">
        <el-input :readonly="true" v-model="form.data.account"></el-input>
      </el-form-item>
      <el-form-item prop="content" label="反馈内容">
        <el-input
          :readonly="true"
          type="textarea"
          v-model="form.data.content"
          :autosize="{ minRows: 2, maxRows: 4}"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="img" label="举报图片">
        <template v-if="form.data.image.length > 0">
          <el-image
            v-for="item in form.data.image"
            :key="item"
            style="width: 100px; height: 100px;margin-right:5px"
            :src="item" 
            :preview-src-list="form.data.image">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
        <template v-else>无举报图片</template>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
  export default {
    name: 'ReportDetail',
    data() {
      return {
        visible: false,
        loading: false,
        form: {
          data: {
            create_time: '',
            type: '',
            account: '',
            content: '',
            image: [],
          }
        },
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail(row) {
        if(!!row) {
          this.$http.get('/admin/feedback/info', {params:{id: row.id}}).then(res => {
            if(res.code === 1 ) {
              this.form.data = res.data
              this.form.data.type = res.data.type == 1 ? '用户' : '企业'
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
    }
  }
</script>
