<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div class="flex">
        <el-form ref="elFormDom" inline :model="table.params">
          <el-form-item label="反馈来源" prop="type">
            <el-select v-model="table.params.type" placeholder="请选择" clearable>
              <el-option label="用户" :value="1"></el-option>
              <el-option label="企业" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="注册号码" prop="account">
            <el-input clearable v-model.trim="table.params.account" placeholder="请输入号码"></el-input>
          </el-form-item>
          <el-form-item label="反馈内容" prop="content">
            <el-input clearable v-model.trim="table.params.content" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询 </el-button>
            <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:type="{row}">
          <span>{{row.type == 1 ? '用户' : '企业'}}</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" size="mini" icon="el-icon-view" @click="showDetail(row)">查看</el-button> 
        </template>
      </VTable> 
    </div>

    <detail ref="detail"></detail>

  </div>
</template>

<script>
import VTable from '@/components/VTable'
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'ReportList',
  mixins:[mixinTable],
  components: {
    VTable,
    Detail,
  },
  data() {
    return {
      field: [
        { name: "type", label: "用户来源", hidden: false },
        { name: "account", label: "注册号码", hidden: false },
        { name: "content", label: "反馈内容", showTooltip: true, hidden: false },
        { name: "create_time", label: "反馈时间", hidden: false },
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          content: '',
          account: '',
          type: '', //类型 1:用户 2:企业
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      options:[
        {value:1,label:'求职端'},
        {value:2,label:'企业端'},
      ],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/feedback/list',  {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.getTable();
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>